<template>
  <div class="accordion-group mb-10">
    <Headline v-if="$props.headline" type="h2" :text="$props.headline" :classes="'text-'+ $props.headline_color + ' font-heavy text-left text-large leading-7 mb-5'" />
    <Accordions v-for="accordion of $props.accordions" :key="accordion.id" :type="$props.type" :head_type="$props.headerType" :head="accordion.Headline" :content="accordion.Content"/>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const Accordions = defineAsyncComponent(() => import("@/components/Accordions"));
const Headline = defineAsyncComponent(() => import('@/components/Headline'));
export default {
  name: "AccordionGroup",
  props: ["accordions", "headline", "headline_color", "type", "headerType"],
  components: {
    Accordions,
    Headline
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
  .accordion-group {
    .accordion:last-child {

      margin-bottom: 0;
    }
  }
</style>